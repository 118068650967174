<template>
  <div class='session-detail-box fixed lg:static lg:flex-grow overflow-y-auto px-2 py-12 lg:p-12 top-16 mt-16 z-40 lg:z-0 rounded'
    :style='backgroundColorStyle'>
  <div class='px-4 lg:px-16 py-4 lg:py-16 flex flex-col items-center justify-center'>
    <img :src='keyImageurl' class='w-40' />
    <div class='mt-16 opacity-80 text-2xl'>{{waitingMessage}}</div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WaitPage',
  props: [
    'waitingMessage'
  ],
  computed: {
    ...mapGetters('events', [
      'eventImageS3Path',
      'colorsInRange',
    ]),
    keyImageurl () {
      return (this.eventImageS3Path) ? `${this.eventImageS3Path}/conference_key_image.png` : 'https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/remedi_logo.png'
    },
    backgroundColorStyle () {
      return `background-color: ${this.colorsInRange[1]}`
    },
  }
}
</script>
       
